import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "gatsby-theme-blog/src/components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

const BadRequestPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      placeholderImage: file(relativePath: { eq: "lacey-lost.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout location={{ pathname: "400" }} title={data.site.siteMetadata.title}>
      <SEO title="400: Bad request" />
      <h1>BARK!</h1>
      <p>You are barking up the wrong tree.</p>
      <Img fluid={data.placeholderImage.childImageSharp.fluid} />
    </Layout>
  );
}

export default BadRequestPage
